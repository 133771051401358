import { ServiceType } from '@wix/ambassador-bookings-services-v2-service/build/cjs/types.impl';
import { BookingsAPI } from '../../api/BookingsApi';
import { WidgetViewModel } from '../../viewModel/viewModel';
import { EnrichedService } from '../../types/types';

export type GetAdditionalServicesData = () => Promise<void>;

export const createGetAdditionalServicesData =
  ({
    widgetViewModel,
    bookingsApi,
    setProps,
  }: {
    widgetViewModel: WidgetViewModel;
    bookingsApi: BookingsAPI;
    setProps: Function;
  }): GetAdditionalServicesData =>
  async () => {
    const { coursesAvailability, services } = await getAdditionalServicesData({
      services: widgetViewModel.services,
      bookingsApi,
    });

    widgetViewModel.services = services;
    widgetViewModel.coursesAvailability = coursesAvailability;

    setProps({
      widgetViewModel: {
        ...widgetViewModel,
      },
    });
  };

const getAndPopulateClassOfferedDays = async ({
  services,
  bookingsApi,
}: {
  services: EnrichedService[];
  bookingsApi: BookingsAPI;
}) => {
  const classServiceIds = services
    .filter(({ type, dummy }) => !dummy && type === ServiceType.CLASS)
    .map(({ id }) => id!);

  if (!classServiceIds.length) {
    return services;
  }

  const classesOfferedDays = await bookingsApi.getOfferedDays(classServiceIds);

  services.forEach((service) => {
    const serviceOfferedDays = classesOfferedDays[service.id!];
    if (serviceOfferedDays) {
      service.offeredDays = serviceOfferedDays;
    }
  });

  return services;
};

const getServicesAvailability = ({
  services,
  bookingsApi,
}: {
  services: EnrichedService[];
  bookingsApi: BookingsAPI;
}) => {
  const serviceIds = services
    .filter(({ type, dummy }) => !dummy && type === ServiceType.COURSE)
    .map(({ id }) => id!);

  if (!serviceIds.length) {
    return;
  }

  return bookingsApi.getServicesAvailability(serviceIds);
};

export const getAdditionalServicesData = async ({
  services,
  bookingsApi,
}: {
  services: EnrichedService[];
  bookingsApi: BookingsAPI;
}) => {
  const [servicesWithOfferedDays, coursesAvailability] = await Promise.all([
    getAndPopulateClassOfferedDays({ services, bookingsApi }).catch((e) => {
      console.log(e);
      return services;
    }),
    getServicesAvailability({ services, bookingsApi })?.catch((e) => {
      console.log(e);
      return undefined;
    }),
  ]);

  return { services: servicesWithOfferedDays, coursesAvailability };
};
