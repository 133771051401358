import {
  ISettingsParamsBooleansKeys,
  ISettingsParamsCustomsKeys,
  ISettingsParamsTextKeys,
} from '../../components/BookOnline/settingsParams';
import {
  DisplayServicesByOptions,
  ServiceListLayoutOptions,
} from '../../types/types';
import type { Experiments, IWixAPI } from '@wix/yoshi-flow-editor';
import { isAnywhereUrl } from '@wix/wix-anywhere-api';
import { parse } from 'jsurl';

export type PublicDataOverrides = {
  displayServicesBy?: DisplayServicesByOptions;
  serviceListLayout?: ServiceListLayoutOptions;
  isTitleVisible?: boolean;
  services?: string[];
  categories?: string[];
  locations?: string[];
  titleText?: string;
};

export const mapPublicDataOverridesToPublicData = (
  experiments: Experiments,
  publicDataOverrides?: string,
  serviceListTitle?: string,
): PublicDataOverrides & {
  titleText?: string;
} => {
  const publicData = {
    [ISettingsParamsTextKeys.titleText]: serviceListTitle,
  };
  if (
    !publicDataOverrides ||
    experiments.enabled('specs.bookings.removeAnywhereEmbed')
  ) {
    return publicData;
  }
  const publicDataOverridesFromUrl: PublicDataOverrides =
    parse(publicDataOverrides);
  return {
    ...(publicDataOverridesFromUrl.displayServicesBy && {
      [ISettingsParamsCustomsKeys.displayServicesBy]:
        publicDataOverridesFromUrl.displayServicesBy,
    }),
    ...(publicDataOverridesFromUrl.serviceListLayout && {
      [ISettingsParamsCustomsKeys.serviceListLayout]:
        publicDataOverridesFromUrl.serviceListLayout,
    }),
    ...('isTitleVisible' in publicDataOverridesFromUrl && {
      [ISettingsParamsBooleansKeys.isTitleVisible]:
        publicDataOverridesFromUrl.isTitleVisible,
    }),
    ...publicData,
  };
};

export const isAnywhereFlow = async (
  wixCodeApi: IWixAPI,
  experiments?: Experiments,
  isPreview?: boolean,
): Promise<boolean> => {
  if (isPreview) {
    return false;
  }
  const isAnywhere = await isAnywhereUrl({
    fullUrl: wixCodeApi.location.url,
    externalBaseUrl: wixCodeApi.location.baseUrl,
  });
  return isAnywhere || false;
};
