import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { WidgetViewModel } from '../../viewModel/viewModel';
import { WidgetViewModel as WidgetViewModelDeprecated } from '../../viewModelDeprecated/viewModel';
import settingsParams from '../../components/BookOnline/settingsParams';
import {
  DisplayServicesByOptions,
  FilterServicesByOptions,
} from '../../types/types';
import { ALL_SERVICES } from '../../consts';
import { ReservedLocationIds } from '@wix/bookings-uou-types';

export const getLocationId = (
  flowAPI: ControllerFlowAPI,
  widgetViewModel: WidgetViewModel | WidgetViewModelDeprecated,
) => {
  const { settings } = flowAPI;
  let locationId = '';
  const isListFilterVisible = settings.get(settingsParams.isListFilterVisible);
  const filterServicesBy = settings.get(settingsParams.filterServicesBy);
  const displayServicesBy = settings.get(settingsParams.displayServicesBy);
  const selectedLocations = settings.get(settingsParams.selectedLocations);
  const isOnlyOneLocationSelected =
    displayServicesBy === DisplayServicesByOptions.BY_LOCATIONS &&
    selectedLocations?.length === 1;

  if (
    isListFilterVisible &&
    filterServicesBy === FilterServicesByOptions.LOCATIONS
  ) {
    const selectedFilterOption = widgetViewModel.filterOptions.find(
      (option) => option.isSelected,
    )!.id;
    locationId =
      selectedFilterOption !== ALL_SERVICES &&
      selectedFilterOption !== ReservedLocationIds.OTHER_LOCATIONS
        ? selectedFilterOption
        : '';
  } else if (isOnlyOneLocationSelected) {
    locationId = selectedLocations[0];
  }
  return locationId;
};
