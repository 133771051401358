import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import {
  createServiceImageViewModel,
  ServiceImageViewModel,
} from './serviceImageViewModel/serviceImageViewModel';
import {
  BodyViewModel,
  createBodyViewModel,
} from './bodyViewModel/bodyViewModel';
import { createFilterOptions } from './filterOptions/filterOptions';
import { createHeaderViewModel } from './headerViewModel/headerViewModel';
import { filterServicesBySelectedTab } from '../utils/services/services';
import {
  FilterOption,
  EnrichedService,
  ServiceListLayoutOptions,
  ViewMode,
} from '../types/types';
import { BusinessInfo as LegacyBusinessInfo } from '../../legacy/types';
import settingsParams from '../components/BookOnline/settingsParams';
import {
  createDialogViewModel,
  DialogViewModel,
} from './dialogViewModel/dialogViewModel';
import {
  Category,
  Location,
} from '@wix/ambassador-bookings-services-v2-service/types';
import { ServiceListContext } from '../context/createServiceListContext';
import { ServiceAvailabilityMap } from '../api/BookingsApi';

export type WidgetViewModel = {
  services: EnrichedService[];
  businessInfo: LegacyBusinessInfo;
  filterOptions: FilterOption[];
  serviceImageViewModel: ServiceImageViewModel;
  headerViewModel?: any;
  bodyViewModel: BodyViewModel;
  seo?: {
    shouldListServicesFromOtherCategories: boolean;
    allServices: EnrichedService[];
  };
  isSEO: boolean;
  serviceListLayout: ServiceListLayoutOptions;
  coursesAvailability?: ServiceAvailabilityMap;
  shouldWorkWithAppSettings: boolean;
  dialogViewModel: DialogViewModel;
  isMultiServiceAppointmentEnabled: boolean;
};

export const createWidgetViewModel = async ({
  flowAPI,
  scale,
  viewMode,
  shouldWorkWithAppSettings,
  businessLocations,
  categories,
  services: allServices,
  serviceListContext,
}: {
  flowAPI: ControllerFlowAPI;
  scale: number;
  viewMode: ViewMode;
  shouldWorkWithAppSettings: boolean;
  businessLocations: Location[];
  categories: Category[];
  services: EnrichedService[];
  serviceListContext: ServiceListContext;
}): Promise<WidgetViewModel> => {
  const {
    settings,
    environment: { isMobile },
  } = flowAPI;
  const { businessInfo, isPricingPlanInstalled } = serviceListContext;

  const filterOptions = createFilterOptions({
    flowAPI,
    businessLocations,
    categories,
    services: allServices,
  });

  const filteredServices = filterServicesBySelectedTab({
    filterOptions,
    services: allServices,
    settings,
  });

  const serviceListLayout = settings.get(settingsParams.serviceListLayout);

  const serviceImageViewModel = createServiceImageViewModel({
    settings,
    isMobile,
    serviceListLayout,
  });

  const bodyViewModel = createBodyViewModel({
    flowAPI,
    scale,
    imageAspectRatio: serviceImageViewModel.aspectRatio,
    serviceListLayout,
    services: filteredServices,
    isPricingPlanInstalled,
  });

  const headerViewModel = createHeaderViewModel(settings, filterOptions);

  const shouldListServicesFromOtherCategories =
    viewMode === ViewMode.PAGE &&
    filteredServices.length !== allServices.length;
  const seo = {
    shouldListServicesFromOtherCategories,
    allServices,
  };

  const dialogViewModel = createDialogViewModel();

  return {
    services: filteredServices,
    filterOptions,
    serviceListLayout,
    serviceImageViewModel,
    headerViewModel,
    bodyViewModel,
    businessInfo,
    seo,
    isSEO: flowAPI.environment.isSEO,
    shouldWorkWithAppSettings,
    dialogViewModel,
    isMultiServiceAppointmentEnabled:
      serviceListContext.isMultiServiceAppointmentEnabled,
  };
};
