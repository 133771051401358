import { CatalogServiceDto, ServiceLocation } from '@wix/bookings-uou-types';
import { OfferingCategoryDto } from '@wix/bookings-uou-domain';

export interface AdditionalBiProps {
  isNewBookingsPlatform?: boolean;
}

export enum FormFactor {
  DESKTOP = 'Desktop',
  MOBILE = 'Mobile',
}

export interface FontProperties {
  size: number;
  editorKey: string;
  htmlTag: string;
}

export enum SelectedOfferingsFilterType {
  ALL = 'ALL',
  SPECIFIC = 'SPECIFIC',
  FIRST = 'FIRST',
}

export enum FilterType {
  SPECIFIC = 'SPECIFIC',
  ALL = 'ALL',
  FIRST = 'FIRST',
}

export interface SelectedResources {
  categories: string[];
  offerings: string[];
  filter: FilterType;
}

export enum SettingsKeys {
  BOOK_FLOW_ACTION_TEXT = 'BOOK_FLOW_ACTION_TEXT',
  EXPLORE_PLANS_ACTION_TEXT = 'EXPLORE_PLANS_ACTION_TEXT',
  NO_BOOK_FLOW_ACTION_TEXT = 'NO_BOOK_FLOW_ACTION_TEXT',
  VIEW_COURSE_BUTTON_TEXT = 'VIEW_COURSE_BUTTON_TEXT',
  PENDING_APPROVAL_FLOW_ACTION_TEXT = 'PENDING_APPROVAL_FLOW_ACTION_TEXT',
  MORE_INFO_LABEL_TEXT = 'MORE_INFO_LABEL_TEXT',
  ONLINE_INDICATION_TEXT = 'ONLINE_INDICATION_TEXT',
  COURSE_AVAILABILITY_TEXT = 'COURSE_AVAILABILITY_TEXT',
  COURSE_AVAILABLE_TEXT = 'COURSE_AVAILABLE_TEXT',
  COURSE_NO_AVAILABILITY_TEXT = 'COURSE_NO_AVAILABILITY_TEXT',
  DISPLAY_IMAGE = 'DISPLAY_IMAGE',
  DISPLAY_TAG_LINE = 'DISPLAY_TAG_LINE',
  DISPLAY_DIVIDER = 'DISPLAY_DIVIDER',
  DISPLAY_PRICE = 'DISPLAY_PRICE',
  DISPLAY_DURATION = 'DISPLAY_DURATION',
  DISPLAY_START_DATE = 'DISPLAY_START_DATE',
  DISPLAY_COURSE_AVAILABILITY = 'DISPLAY_COURSE_AVAILABILITY',
  DISPLAY_NUMBER_OF_SPOTS = 'DISPLAY_NUMBER_OF_SPOTS',
  DISPLAY_DAYS_OFFERED = 'DISPLAY_DAYS_OFFERED',
  DISPLAY_BUTTON = 'DISPLAY_BUTTON',
  DISPLAY_EXPLORE_PLANS = 'DISPLAY_EXPLORE_PLANS',
  DISPLAY_MORE_INFO_LABEL = 'DISPLAY_MORE_INFO_LABEL',
  DISPLAY_MULTI_OFFERINGS_TITLE = 'DISPLAY_MULTI_OFFERINGS_TITLE',
  DISPLAY_CATEGORIES = 'DISPLAY_CATEGORIES',
  DISPLAY_ONLINE_INDICATION = 'DISPLAY_ONLINE_INDICATION',
  CATEGORIES_TYPE = 'CATEGORIES_TYPE',
  FILTER_BY = 'FILTER_BY',
  SELECTED_LOCATIONS = 'SELECTED_LOCATIONS',
  SERVICE_CLICK_TARGET = 'SERVICE_CLICK_TARGET',
  DISPLAY_SERVICE_DIVIDER = 'DISPLAY_SERVICE_DIVIDER',
  TEXT_ALIGNMENT = 'TEXT_ALIGNMENT',
  IMAGE_RESIZE_OPTION = 'IMAGE_RESIZE_OPTION',
  IMAGE_SIZE_OPTION = 'IMAGE_SIZE_OPTION',
  IMAGE_POSITION_OPTION = 'IMAGE_POSITION_OPTION',
  IMAGE_SHAPE_OPTION = 'IMAGE_SHAPE_OPTION',
  PIN_LOCATION = 'PIN_LOCATION',
  IMAGE_AND_TEXT_POSITION = 'IMAGE_AND_TEXT_POSITION',
  IMAGE_AND_TEXT_RATIO = 'IMAGE_AND_TEXT_RATIO',
  IMAGE_AND_TEXT_RATIO_IS_FLIPED = 'IMAGE_AND_TEXT_RATIO_IS_FLIPED',
  SELECTED_RESOURCES = 'SELECTED_RESOURCES',
  OFFERING_NAME_FONT = 'OFFERING_NAME_FONT',
  OFFERING_NAME_COLOR = 'OFFERING_NAME_COLOR',
  OFFERING_TAGLINE_FONT = 'OFFERING_TAGLINE_FONT',
  OFFERING_TAGLINE_COLOR = 'OFFERING_TAGLINE_COLOR',
  OFFERING_MORE_INFO_LABEL_FONT = 'OFFERING_MORE_INFO_LABEL_FONT',
  OFFERING_MORE_INFO_LABEL_COLOR = 'OFFERING_MORE_INFO_LABEL_COLOR',
  OFFERING_DETAILS_FONT = 'OFFERING_DETAILS_FONT',
  OFFERING_DETAILS_COLOR = 'OFFERING_DETAILS_COLOR',
  COURSE_AVAILABILITY_COLOR = 'COURSE_AVAILABILITY_COLOR',
  COURSE_AVAILABILITY_FONT = 'COURSE_AVAILABILITY_FONT',
  BACKGROUND_COLOR = 'BACKGROUND_COLOR',
  BORDER_COLOR = 'BORDER_COLOR',
  IMAGE_BACKGROUND_COLOR = 'IMAGE_BACKGROUND_COLOR',
  DIVIDER_COLOR = 'DIVIDER_COLOR',
  SERVICE_DIVIDER_COLOR = 'SERVICE_DIVIDER_COLOR',
  BORDER_WIDTH = 'BORDER_WIDTH',
  STRIP_BORDER_WIDTH = 'STRIP_BORDER_WIDTH',
  CORNER_RADIUS = 'CORNER_RADIUS',
  DIVIDER_WIDTH = 'DIVIDER_WIDTH',
  SERVICE_DIVIDER_WIDTH = 'SERVICE_DIVIDER_WIDTH',
  BUTTON_STYLE = 'BUTTON_STYLE',
  BUTTON_TEXT_FONT = 'BUTTON_TEXT_FONT',
  BUTTON_TEXT_COLOR_FILL = 'BUTTON_TEXT_COLOR_FILL',
  BUTTON_TEXT_COLOR_HOLE = 'BUTTON_TEXT_COLOR_HOLE',
  BUTTON_HOVER_TEXT_COLOR = 'BUTTON_HOVER_TEXT_COLOR',
  BUTTON_BORDER_COLOR = 'BUTTON_BORDER_COLOR',
  BUTTON_HOVER_BORDER_COLOR = 'BUTTON_HOVER_BORDER_COLOR',
  BUTTON_BACKGROUND_COLOR = 'BUTTON_BACKGROUND_COLOR',
  BUTTON_HOVER_BACKGROUND_COLOR = 'BUTTON_HOVER_BACKGROUND_COLOR',
  BUTTON_CORNER_RADIUS = 'BUTTON_CORNER_RADIUS',
  SERVICE_IMAGE_CORNER_RADIUS = 'SERVICE_IMAGE_CORNER_RADIUS',
  SERVICE_IMAGE_PADDING = 'SERVICE_IMAGE_PADDING',
  BUTTON_BORDER_WIDTH = 'BUTTON_BORDER_WIDTH',
  ALTERNATE_IMAGE_INFO_SIDES = 'ALTERNATE_IMAGE_INFO_SIDES',
  EXPLORE_PLANS_FONT = 'EXPLORE_PLANS_FONT',
  EXPLORE_PLANS_TEXT_COLOR = 'EXPLORE_PLANS_TEXT_COLOR',
  MULTI_OFFERINGS_TITLE_TEXT = 'MULTI_OFFERINGS_TITLE_TEXT',
  OFFERING_LIST_LAYOUT = 'OFFERINGS_LIST_LAYOUT',
  CATEGORY_NAME_FONT = 'CATEGORY_NAME_FONT',
  CATEGORY_NAME_COLOR = 'CATEGORY_NAME_COLOR',
  CATEGORY_DIVIDER_COLOR = 'CATEGORY_DIVIDER_COLOR',
  CATEGORY_ALL_SERVICES_SHOW = 'CATEGORY_ALL_SERVICES_SHOW',
  CATEGORY_ALL_SERVICES_TEXT = 'CATEGORY_ALL_SERVICES_TEXT',
  CATEGORY_SELECTED_UNDERLINE_COLOR = 'CATEGORY_SELECTED_UNDERLINE_COLOR',
  CATEGORY_ALIGNMENT = 'CATEGORY_ALIGNMENT',
  MULTI_OFFERINGS_TITLE_ALIGNMENT = 'MULTI_OFFERINGS_TITLE_ALIGNMENT',
  MULTI_OFFERINGS_TITLE_COLOR = 'MULTI_OFFERINGS_TITLE_COLOR',
  MULTI_OFFERINGS_TITLE_FONT = 'MULTI_OFFERINGS_TITLE_FONT',
  FIT_CATEGORY_WIDTH = 'FIT_CATEGORY_WIDTH',
  MULTI_OFFERINGS_BACKGROUND_COLOR = 'MULTI_OFFERINGS_BACKGROUND_COLOR',
  SPACE_BETWEEN_OFFERINGS = 'SPACE_BETWEEN_OFFERINGS',
  STRIP_SPACE_BETWEEN_OFFERINGS = 'STRIP_SPACE_BETWEEN_OFFERINGS',
  LAYOUT_CARDS_PER_ROW = 'LAYOUT_CARDS_PER_ROW',
  CARDS_SPACING = 'CARDS_SPACING',
  STRIP_SIDE_PADDING = 'LAYOUT_SIDE_PADDING',
  GRID_SIDE_PADDING = 'INFO_SIDE_PADDING',
  CLASSIC_SIDE_PADDING = 'CLASSIC_SIDE_PADDING',
  OVERLAPPING_SIDE_PADDING = 'OVERLAPPING_SIDE_PADDING',
  STRIP_VERTICAL_PADDING = 'LAYOUT_VERTICAL_PADDING',
  GRID_VERTICAL_PADDING = 'INFO_VERTICAL_PADDING',
  CLASSIC_VERTICAL_PADDING = 'CLASSIC_VERTICAL_PADDING',
  OVERLAPPING_VERTICAL_PADDING = 'OVERLAPPING_VERTICAL_PADDING',
  STRIP_SIDE_PADDING_UNIT = 'LAYOUT_SIDE_PADDING_UNIT',
  GRID_SIDE_PADDING_UNIT = 'INFO_SIDE_PADDING_UNIT',
  CLASSIC_SIDE_PADDING_UNIT = 'CLASSIC_SIDE_PADDING_UNIT',
  OVERLAPPING_SIDE_PADDING_UNIT = 'OVERLAPPING_SIDE_PADDING_UNIT',
  STRIP_VERTICAL_PADDING_UNIT = 'LAYOUT_VERTICAL_PADDING_UNIT',
  GRID_VERTICAL_PADDING_UNIT = 'INFO_VERTICAL_PADDING_UNIT',
  CLASSIC_VERTICAL_PADDING_UNIT = 'CLASSIC_VERTICAL_PADDING_UNIT',
  OVERLAPPING_VERTICAL_PADDING_UNIT = 'OVERLAPPING_VERTICAL_PADDING_UNIT',
  CATEGORY_LAYOUT_OPTION = 'CATEGORY_LAYOUT_OPTION',
  CATEGORY_BORDER_WIDTH = 'CATEGORY_BORDER_WIDTH',
  CATEGORY_BORDER_RADIUS = 'CATEGORY_BORDER_RADIUS',
  CATEGORY_BACKGROUND_COLOR = 'CATEGORY_BACKGROUND_COLOR',
  CATEGORY_SPACING = 'CATEGORY_SPACING',
  CATEGORY_SELECTED_TEXT_COLOR = 'CATEGORY_SELECTED_TEXT_COLOR',
  CATEGORY_SELECTED_BORDER_COLOR = 'CATEGORY_SELECTED_BORDER_COLOR',
  CATEGORY_HOVER_BACKGROUND_COLOR = 'CATEGORY_HOVER_BACKGROUND_COLOR',
  CATEGORY_HOVER_TEXT_COLOR = 'CATEGORY_HOVER_TEXT_COLOR',
  CATEGORY_HOVER_BORDER_COLOR = 'CATEGORY_HOVER_BORDER_COLOR',
  PRESERVE_OLD_STYLES = 'PRESERVE_OLD_STYLES',
  MOBILE_OFFERING_NAME_FONT_SIZE = 'MOBILE_OFFERING_NAME_FONT_SIZE',
}

export enum MobileSettingsKeys {
  MOBILE_OFFERING_LIST_LAYOUT = 'MOBILE_OFFERING_LIST_LAYOUT',
  MOBILE_STRIP_VERTICAL_PADDING = 'MOBILE_STRIP_VERTICAL_PADDING',
  MOBILE_CARDS_SPACING = 'MOBILE_CARDS_SPACING',
  MOBILE_IMAGE_RESIZE_OPTION = 'MOBILE_IMAGE_RESIZE_OPTION',
  MOBILE_IMAGE_SIZE_OPTION = 'MOBILE_IMAGE_SIZE_OPTION',
  MOBILE_IMAGE_SHAPE_OPTION = 'MOBILE_IMAGE_SHAPE_OPTION',
  MOBILE_TEXT_ALIGNMENT = 'MOBILE_TEXT_ALIGNMENT',
  MOBILE_DISPLAY_IMAGE = 'MOBILE_DISPLAY_IMAGE',
  MOBILE_DISPLAY_TAG_LINE = 'MOBILE_DISPLAY_TAG_LINE',
  MOBILE_DISPLAY_DIVIDER = 'MOBILE_DISPLAY_DIVIDER',
  MOBILE_DISPLAY_PRICE = 'MOBILE_DISPLAY_PRICE',
  MOBILE_DISPLAY_DURATION = 'MOBILE_DISPLAY_DURATION',
  MOBILE_DISPLAY_START_DATE = 'MOBILE_DISPLAY_START_DATE',
  MOBILE_DISPLAY_COURSE_AVAILABILITY = 'MOBILE_DISPLAY_COURSE_AVAILABILITY',
  MOBILE_DISPLAY_NUMBER_OF_SPOTS = 'MOBILE_DISPLAY_NUMBER_OF_SPOTS',
  MOBILE_DISPLAY_DAYS_OFFERED = 'MOBILE_DISPLAY_DAYS_OFFERED',
  MOBILE_DISPLAY_BUTTON = 'MOBILE_DISPLAY_BUTTON',
  MOBILE_DISPLAY_EXPLORE_PLANS = 'MOBILE_DISPLAY_EXPLORE_PLANS',
  MOBILE_DISPLAY_MORE_INFO_LABEL = 'MOBILE_DISPLAY_MORE_INFO_LABEL',
  MOBILE_DIVIDER_WIDTH = 'MOBILE_DIVIDER_WIDTH',
  MOBILE_BORDER_WIDTH = 'MOBILE_BORDER_WIDTH',
  MOBILE_CORNER_RADIUS = 'MOBILE_CORNER_RADIUS',
  MOBILE_MULTI_OFFERINGS_TITLE_FONT_SIZE = 'MOBILE_MULTI_OFFERINGS_TITLE_FONT_SIZE',
  MOBILE_CATEGORY_NAME_FONT_SIZE = 'MOBILE_CATEGORY_NAME_FONT_SIZE',
  MOBILE_OFFERING_NAME_FONT_SIZE = 'MOBILE_OFFERING_NAME_FONT_SIZE',
  MOBILE_OFFERING_TAGLINE_FONT_SIZE = 'MOBILE_OFFERING_TAGLINE_FONT_SIZE',
  MOBILE_MORE_INFO_LABEL_FONT_SIZE = 'MOBILE_MORE_INFO_LABEL_FONT_SIZE',
  MOBILE_OFFERING_DETAILS_FONT_SIZE = 'MOBILE_OFFERING_DETAILS_FONT_SIZE',
  MOBILE_BUTTON_TEXT_FONT_SIZE = 'MOBILE_BUTTON_TEXT_FONT_SIZE',
  MOBILE_BUTTON_STYLE = 'MOBILE_BUTTON_STYLE',
  MOBILE_EXPLORE_PLANS_FONT_SIZE = 'MOBILE_EXPLORE_PLANS_FONT_SIZE',
  MOBILE_PIN_LOCATION = 'MOBILE_PIN_LOCATION',
  MOBILE_SIDE_PADDING = 'MOBILE_SIDE_PADDING',
  MOBILE_VERTICAL_PADDING = 'MOBILE_VERTICAL_PADDING',
  MOBILE_SIDE_PADDING_UNIT = 'MOBILE_SIDE_PADDING_UNIT',
  MOBILE_VERTICAL_PADDING_UNIT = 'MOBILE_VERTICAL_PADDING_UNIT',
  MOBILE_CATEGORY_LAYOUT_OPTION = 'MOBILE_CATEGORY_LAYOUT_OPTION',
  MOBILE_CATEGORY_BORDER_WIDTH = 'MOBILE_CATEGORY_BORDER_WIDTH',
  MOBILE_CATEGORY_NAME_COLOR = 'MOBILE_CATEGORY_NAME_COLOR',
  MOBILE_CATEGORY_BACKGROUND_COLOR = 'MOBILE_CATEGORY_BACKGROUND_COLOR',
  MOBILE_CATEGORY_BORDER_COLOR = 'MOBILE_CATEGORY_BORDER_COLOR',
  MOBILE_CATEGORY_NAME_FONT = 'MOBILE_CATEGORY_NAME_FONT',
  MOBILE_DISPLAY_ONLINE_INDICATION = 'MOBILE_DISPLAY_ONLINE_INDICATION',
}

/*
important please add all the font keys into this list fix for #SCHED-9559
*/
export const AllFontsInTheApp = [
  SettingsKeys.OFFERING_NAME_FONT,
  SettingsKeys.OFFERING_TAGLINE_FONT,
  SettingsKeys.OFFERING_MORE_INFO_LABEL_FONT,
  SettingsKeys.OFFERING_DETAILS_FONT,
  SettingsKeys.BUTTON_TEXT_FONT,
  SettingsKeys.CATEGORY_NAME_FONT,
  SettingsKeys.MULTI_OFFERINGS_TITLE_FONT,
  MobileSettingsKeys.MOBILE_CATEGORY_NAME_FONT,
];

export interface Metadata {
  timings: {
    start: string;
    end: string;
  };
  cacheInfo?: {
    isCachedResponse: boolean;
    maxAge: number;
    cacheInvalidateDate: string;
    cacheInvalidateCause: 'SERVICE' | 'MAX_AGE';
    cacheInvalidateInfo: any;
  };
}

export type BusinessInfo = {
  name: string;
  language: string;
  timeZone: string;
  regionalSettings: string;
  timezoneProperties?: {
    defaultTimezone?: string;
  };
};

export interface WidgetConfig {
  resourcesFiltered?: boolean;
  baseUrl?: string;
  locale?: string;
  regionalSettings?: string;
  staticBaseUrl?: string;
  activeFeatures: any;
  businessInfo: any;
  experiments?: any;
  settings?: any;
  biProps?: AdditionalBiProps;
}

export interface WidgetData {
  offerings: CatalogServiceDto[];
  categories: OfferingCategoryDto[];
  locations: ServiceLocation[];
  config: WidgetConfig;
  metadata?: Metadata;
}
